import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"

import { breakpoints, colours, horizontal } from "design-kit"

import LogoView, { Variation } from "../../Logo"
import { NavTheme } from "./Theme"
import { Mobile, Desktop } from "./Breakpoints"

export type PartnerLogo = { src: string; alt: string }

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Vr = styled.div<{ borderColor: string }>`
  align-self: stretch;
  ${props => `border-left: 1px solid ${props.borderColor};`}
  margin-right: ${horizontal.xs};
  width: 1px;
`

const PartnerContainer = styled.div`
  width: 80px;

  ${breakpoints.desktop`
    width: 160px;
  `}
`

const PartnerImg = styled.img`
  max-width: 100%;
`

type Logo = FunctionComponent<{
  navTheme: NavTheme
  partnerLogo?: PartnerLogo
  variation: Variation
  mobileMenuOpen?: boolean
}>
const Logo: Logo = ({ partnerLogo, variation, navTheme, mobileMenuOpen }) => {
  const logoColour = mobileMenuOpen
    ? colours.offBlack
    : navTheme === "transparent"
    ? colours.white
    : colours.offBlack

  return (
    <Container>
      <Mobile>
        <LogoView
          href="/"
          variation={variation}
          color={logoColour}
          height={24}
        />
      </Mobile>
      <Desktop>
        <LogoView
          href="/"
          variation={variation}
          color={logoColour}
          height={40}
        />
      </Desktop>
      {partnerLogo && (
        <React.Fragment>
          <Vr borderColor={logoColour} />
          <PartnerContainer>
            <PartnerImg src={partnerLogo.src} alt={partnerLogo.alt} />
          </PartnerContainer>
        </React.Fragment>
      )}
    </Container>
  )
}
export default Logo
